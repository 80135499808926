body {
  margin: 0px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background: #333333;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.785714rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

:root {
  --side-menu-width: 320px;

  --text-color-primary: '#EDA30E';
  --text-color-secondary: '#FFFFFF';
}

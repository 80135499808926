.content-with-sidebar-container {
    /* fallback for dvh */
    height: 100vh; 
    height: 100dvh; 
    overflow: hidden;
}

.side-menu-overlay {
    position: absolute;
    width: 100vw;
    /* fallback for dvh */
    height: 100vh;
    height: 100dvh;
    background: rgba(0,0,0,0.8);
    z-index: 1;
    transition: opacity 0.2s cubic-bezier(0.0, 0.0, 0.2, 1);

}
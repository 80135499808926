@keyframes showBtns {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

@keyframes hideBtns {
  0% {
    display: flex;
    opacity: 1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes showDivideLine {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: initial;
    opacity: 0.2;
  }
}

@keyframes hideDivideLine {
  0% {
    display: initial;
    opacity: 0.2;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

.filter-action-btn.collapsed {
  animation: hideBtns 0.2s ease-in forwards;
}

.filter-action-btn-container.expanded {
  animation: showBtns 0.2s ease-in forwards;
}

.filter-divide-line.collapsed {
  animation: hideDivideLine 0.2s ease-in forwards;
}
.filter-divide-line.expanded {
  animation: showDivideLine 0.2s ease-in forwards;
}

@keyframes instantCollapseSpace {
  100% {
    width: 78px;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-360px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    transform: translateX(-360px);
    opacity: 0;
  }
}

/* Container that occupied space */
.side-menu-container {
  display: block;
  width: 320px;
  height: 100%;
}

.instant-collapse {
  animation: instantCollapseSpace 0.1s forwards;
  /* Will wait until side-menu animation is done */
  animation-delay: 0.15s;
}

.mobile-initial-container {
  display: none;
}

.slide-in {
  animation: slideIn 0.2s ease-in forwards;
}
.slide-out {
  animation: slideOut 0.2s ease-in forwards;
}

.side-menu {
  width: var(--side-menu-width);
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 02px 0 5px 6px;
  overflow: hidden;
  transition: width 0.2s 0.1s cubic-bezier(0, 0, 0.2, 1);
  position: fixed;
  z-index: 2;
}

.side-menu.collapsed {
  opacity: 1;
  width: 78px;
}

.content {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
}

.content.fade-out {
  opacity: 0;
}

.divide-line-top {
  width: 90%;
  height: 1px;
  background: #6c768e;
  position: absolute;
  z-index: 2;
  top: 196px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}

.divide-line-bottom {
  width: 90%;
  height: 1px;
  background: #6c768e;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}

.divide-line.collapsed {
  opacity: 0;
}

/* a overlay to prevent unwanted user interaction */
.toggle-btn-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.toggle-btn {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.1s linear;
}

.toggle-btn-icon {
  width: 28px;
  height: 20px;
  margin: 16px;
}

.toggle-btn.collapse {
  color: #7f9ac4;
  top: 22px;
  right: 16px;
}

.toggle-btn.expand {
  color: #ffffff;
  top: 22px;
  right: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.toggle-btn .arrow {
  width: 12px;
  margin-right: 4px;
}

.toggle-btn .text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 11px;
  white-space: nowrap;
}

.toggle-btn.fade-in-out {
  animation: fadeInOut 0.3s linear forwards;
}

.bg {
  background-color: aquamarine;
}

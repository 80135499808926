.login-page {
  width: 100vw;
  /* fallback for dvh */
  height: 100vh;
  height: 100dvh;
  background: #181818;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: auto;
  padding: 32px;
  box-sizing: border-box;
}

.login-page .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 512px;
  max-height: 100%;
  /* overflow: hidden; */
}

.login-page .logo-container {
  display: contents;
}

.login-page .login-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  padding-bottom: 40px;
}

.login-form .title-container {
  height: 48px;
  width: 100%;
  border-radius: inherit;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-form .title-container .back-btn {
  position: absolute;
  left: 16px;
  cursor: pointer;
}
.login-form .title-container .title {
  font-size: 1.2rem;
  font-weight: bold;
}

.login-form .subtitle-container {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 20px;
}

.login-form .subtitle-container .subtitle {
  font-size: 0.8rem;
  opacity: 0.5;
  font-weight: lighter;
}

.login-page .login-form .fields-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form .submit-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 24px * 2);
  height: 44px;
  background-color: #ffd100;
  margin: 0 24px;
  border: #303030 1px solid;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}

.login-page .submit-btn {
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.login-page .submit-btn:active {
  transform: translateY(4px);
}

.login-page .login-field {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.login-page .forgot-password-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.email-verification-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.email-display-container {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: -14px;
  margin-bottom: 20px;
}

.email-verification-container :not(:last-of-type) {
  margin-right: 8px;
}

.email-verification-char {
  width: 19px;
  height: 36px;
  background-color: initial;
  border: inherit;
  border-bottom: 2px solid #ffffff;
  font-size: 28px;
  color: #ffffff;
  flex-grow: initial;
  flex-basis: initial;
  line-height: normal;
}

.email-verification-char-selected {
  border-bottom: 2px solid #ffd100;
  outline: initial;
}

.resend-btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.resend-btn {
  border: initial;
  width: fit-content;
  padding: 0;
  background-color: initial;
  color: #ffd100;
  border-bottom: 1px solid #ffd100;
  cursor: pointer;
}
